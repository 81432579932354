import * as React from 'react';
import { ApiUserStatusT, UserStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status?: ApiUserStatusT | null | undefined;
};

export const USER_STATUS_T_MAP: Record<ApiUserStatusT, string> = {
    [UserStatusEnum.active]: 'common:user-status.ACTIVE',
    [UserStatusEnum.pending]: 'common:user-status.PENDING',
    [UserStatusEnum.archived]: 'common:user-status.ARCHIVED',
};

const UserStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(USER_STATUS_T_MAP[status]);
});

export default UserStatus;
