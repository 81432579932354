import {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { StyleGuideColorsEnum } from 'common/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from 'common/icons/CloseIcon';
import { UserStatusEnum } from 'common/utils/api/models';
import EditIcon from 'common/icons/EditIcon';
import { logWarning } from 'common/utils/logger';
import DeleteUserIcon from 'common/icons/DeleteUserIcon';
import RestoreIcon from 'common/icons/RestoreIcon';
import { CompanyDriverDetailsT } from 'common/store/drivers/models';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';

type CallbacksT = {
    onEdit?: (driverId: DriverIdT) => void;
    onCancelInvite: (params: { driverPhoneNumber: string; driverUserId: UserIdT; companyId: PartnerIdT }) => void;
    onArchiveDriver: (params: {
        companyId: PartnerIdT;
        driverId: DriverIdT;
        driverName: string;
        driverSurname: string;
    }) => void;
    onActivateDriver: (params: {
        companyId: PartnerIdT;
        driverId: DriverIdT;
        driverName: string;
        driverSurname: string;
    }) => void;
};

const EMPTY_OPTIONS: Array<DropdownControlOptionT> = [];

export const useDriverActionOptions = (
    driverDetails: CompanyDriverDetailsT | null,
    partnerId: PartnerIdT,
    callbacks: CallbacksT,
): Array<DropdownControlOptionT | SpecialOptionEnum | null> => {
    const { t } = useTranslation();

    const permissions = useSelector(selectPermissions);
    if (!permissions.canEditDrivers) {
        return EMPTY_OPTIONS;
    }

    const isShowEditAction = driverDetails?.status === UserStatusEnum.active;
    const isShowCancelInviteAction = driverDetails?.status === UserStatusEnum.pending;
    const isShowRestoreAction = driverDetails?.status === UserStatusEnum.archived;
    const isShowArchiveAction = driverDetails?.status === UserStatusEnum.active;

    const driverId = driverDetails?.id;
    if (!driverId) {
        return EMPTY_OPTIONS;
    }

    const { onEdit } = callbacks;

    return [
        ...(isShowEditAction && onEdit
            ? [
                  {
                      label: (
                          <DropdownControlOptionLabel
                              label={t('common:drivers-page.actions.edit')}
                              icon={<EditIcon />}
                          />
                      ),
                      onSelect: () => {
                          if (!driverId) {
                              logWarning('empty selectedDriverId');
                              return;
                          }

                          onEdit(driverId);
                      },
                  },
                  SpecialOptionEnum.separator,
              ]
            : []),
        isShowCancelInviteAction
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          label={t('common:drivers-page.actions.cancel-invite')}
                          icon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                      />
                  ),
                  onSelect: () => {
                      if (!driverId) {
                          logWarning('empty selectedDriverId');
                          return;
                      }

                      const driverUserId = driverDetails?.contact?.userId;
                      if (!driverUserId) {
                          logWarning('empty driverUserId');
                          return;
                      }

                      callbacks.onCancelInvite({
                          driverPhoneNumber: driverDetails?.contact?.mobilePhone || '',
                          driverUserId,
                          companyId: partnerId,
                      });
                  },
              }
            : null,
        isShowArchiveAction
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          label={t('common:drivers-page.actions.archive')}
                          icon={
                              <DeleteUserIcon
                                  fillColor={StyleGuideColorsEnum.white}
                                  strokeColor={StyleGuideColorsEnum.tomatoRed}
                              />
                          }
                      />
                  ),
                  onSelect: () => {
                      callbacks.onArchiveDriver({
                          companyId: partnerId,
                          driverId,
                          driverName: driverDetails?.contact?.name || '',
                          driverSurname: driverDetails?.contact?.surname || '',
                      });
                  },
              }
            : null,
        isShowRestoreAction
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          label={t('common:drivers-page.actions.restore')}
                          icon={<RestoreIcon strokeColor={StyleGuideColorsEnum.slate} />}
                      />
                  ),
                  onSelect: () => {
                      callbacks.onActivateDriver({
                          companyId: partnerId,
                          driverId,
                          driverName: driverDetails?.contact?.name || '',
                          driverSurname: driverDetails?.contact?.surname || '',
                      });
                  },
              }
            : null,
    ];
};
