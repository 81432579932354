import { FieldsEnum, FormValuesT } from './constants';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'common/layouts/DriversPageLayout/query-models';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.name]: queryFilters[QueryFiltersKeysEnum.name] || '',
    };

    return values;
};

export default getInitialValues;
