import BaseTranziitApi, { throwNotImplementedError, TranziitApiResultT } from './base-tranziit-api';
import toNumber from 'lodash/toNumber';
import { authApi } from 'common/utils/api/auth/factory';
import { DocumentTypeEnum, FixPageableCommonQueryT } from 'common/utils/api/models';
import { MS_IN_MIN } from 'common/utils/time';

class CommonTranziitApi extends BaseTranziitApi {
    private static instance: CommonTranziitApi;

    private constructor() {
        super(authApi, {
            basepath: '',
            timeout: MS_IN_MIN,
        });
    }

    public static getInstance(): CommonTranziitApi {
        if (!CommonTranziitApi.instance) {
            CommonTranziitApi.instance = new CommonTranziitApi();
        }

        return CommonTranziitApi.instance;
    }

    getCountries = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1Countries.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Countries.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/countries`,
            expectedResponseContentType: 'json',
        });
    };

    createUser = async (newUser: TODO): Promise<TODO> => {
        return throwNotImplementedError();
    };

    fetchCurrentUser = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1User.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1User.Get.Responses.$200>({
            method: 'GET',
            path: '/api/v1/user',
            expectedResponseContentType: 'json',
        });
    };

    patchUser = async (
        userChanges: ApiPaths.ApiV1User.Put.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1User.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1User.Put.Responses.$200>({
            data: userChanges,
            method: 'PUT',
            path: '/api/v1/user',
            expectedResponseContentType: 'json',
        });
    };

    fetchCurrentCompany = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1Company.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Company.Get.Responses.$200>({
            method: 'GET',
            path: '/api/v1/company',
            expectedResponseContentType: 'json',
        });
    };

    patchCompany = async (
        companyChanges: ApiPaths.ApiV1CompanyV2.Put.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1CompanyV2.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1CompanyV2.Put.Responses.$200>({
            data: companyChanges,
            method: 'PUT',
            path: '/api/v1/company/v2',
            expectedResponseContentType: 'unknown',
        });
    };

    getTrailersDict = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1Dicttrailers.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Dicttrailers.Get.Responses.$200>({
            method: 'GET',
            path: '/api/v1/dicttrailers',
            expectedResponseContentType: 'json',
        });
    };

    fetchTransportOrdersStatistics = async (): Promise<
        TranziitApiResultT<Api2Paths.ApiV2CarrierTransportationOrdersStatistic.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2CarrierTransportationOrdersStatistic.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v2/carrier/transportation-orders/statistic`,
            expectedResponseContentType: 'json',
        });
    };

    fetchOrdersStatistics = async (): Promise<TranziitApiResultT<Api2Paths.ApiV2OrderStatistic.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2OrderStatistic.Get.Responses.$200>({
            method: 'GET',
            path: '/api/v2/order/statistic',
            expectedResponseContentType: 'json',
        });
    };

    fetchAdditionalServices = async (): Promise<
        TranziitApiResultT<ApiPaths.ApiV1AdditionalServices.Get.Responses.$200>
    > => {
        return this.doFetch<ApiPaths.ApiV1AdditionalServices.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/additionalServices`,
            expectedResponseContentType: 'json',
        });
    };

    getTrucksDict = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1Dicttrucks.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Dicttrucks.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/dicttrucks`,
            expectedResponseContentType: 'json',
        });
    };

    fetchCarriersUtilization = async (
        carrierIds: ApiPaths.ApiV1UtilizationGetForCarriers.Get.Parameters.CarrierIds,
        date: ApiPaths.ApiV1UtilizationGetForCarriers.Get.Parameters.Period,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1UtilizationGetForCarriers.Get.Responses.$200>> => {
        const query: ApiPaths.ApiV1UtilizationGetForCarriers.Get.QueryParameters = {
            carrierIds,
            period: date,
        };

        return this.doFetch<ApiPaths.ApiV1UtilizationGetForCarriers.Get.Responses.$200>({
            method: 'GET',
            path: '/api/v1/utilization/getForCarriers',
            query,
            expectedResponseContentType: 'json',
        });
    };

    forgotPassword = async (email: TODO, origin: string): Promise<TODO> => {
        return throwNotImplementedError();
    };

    addUserToCurrentCompany = async (
        data: Api2Paths.ApiV2RegistrationInvite.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2RegistrationInvite.Post.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2RegistrationInvite.Post.Responses.$200>({
            method: 'POST',
            path: `/api/v2/registration/invite`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    addContactToCurrentCompany = async (
        newContact: ApiPaths.ApiV1Contact.Post.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Contact.Post.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Contact.Post.Responses.$200>({
            method: 'POST',
            path: `/api/v1/contact`,
            data: newContact,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchDocumentsDict = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1Dictdocuments.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Dictdocuments.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/dictdocuments`,
            expectedResponseContentType: 'json',
        });
    };

    fetchCountryLegalForms = async (
        countryCode: ApiPaths.ApiV1Dictlegalforms.Get.Parameters.CountryCode,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Dictlegalforms.Get.Responses.$200>> => {
        const query: ApiPaths.ApiV1Dictlegalforms.Get.QueryParameters = {
            countryCode,
        };

        return this.doFetch<ApiPaths.ApiV1Dictlegalforms.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/dictlegalforms`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchNotificationsPage = async (
        query: FixPageableCommonQueryT,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1Notifications.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1Notifications.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/notifications`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchUnReadNotifications = async (): Promise<
        TranziitApiResultT<Api2Paths.ApiV1NotificationsUnread.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1NotificationsUnread.Get.Responses.$200>({
            method: 'GET',
            path: '/api/v1/notifications/unread',
            expectedResponseContentType: 'json',
        });
    };

    fetchUnreadGroupedNotificationCounts = async (): Promise<
        TranziitApiResultT<Api2Paths.ApiV1NotificationsUnreadCountByActionByStatus.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1NotificationsUnreadCountByActionByStatus.Get.Responses.$200>({
            method: 'GET',
            path: '/api/v1/notifications/unread/count-by-action-by-status',
            expectedResponseContentType: 'json',
        });
    };

    markAsRead = async (
        ids: Api2Paths.ApiV1NotificationsMarkAsRead.Put.Parameters.Ids,
        isAll?: boolean,
    ): Promise<
        TranziitApiResultT<
            | Api2Paths.ApiV1NotificationsMarkAsRead.Put.Responses.$200
            | Api2Paths.ApiV1NotificationsMarkAsReadAll.Put.Responses.$200
        >
    > => {
        const path = isAll ? '/api/v1/notifications/mark-as-read/all' : '/api/v1/notifications/mark-as-read';
        const query: Api2Paths.ApiV1NotificationsMarkAsRead.Put.QueryParameters | {} = isAll ? {} : { ids };

        return this.doFetch<
            | Api2Paths.ApiV1NotificationsMarkAsRead.Put.Responses.$200
            | Api2Paths.ApiV1NotificationsMarkAsReadAll.Put.Responses.$200
        >({
            method: 'PUT',
            path,
            query,
            expectedResponseContentType: 'unknown',
        });
    };

    sendTestNotification = async (data: object): Promise<TranziitApiResultT<null>> => {
        return this.doFetch<null>({
            method: 'POST',
            path: `/api/v1/test/notification`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchDocuments = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1DocumentsV2.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1DocumentsV2.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/documents/v2`,
            expectedResponseContentType: 'json',
        });
    };

    fetchDocument = async (
        documentId: ApiPaths.ApiV1Document$DocumentIdGetFile.Get.Parameters.DocumentId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Document$DocumentIdGetFile.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Document$DocumentIdGetFile.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/document/${documentId}/getFile`,
            expectedResponseContentType: 'blob',
        });
    };

    uploadDocument = async (
        documentType: DocumentTypeEnum,
        file: File,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1DocumentV2.Post.Responses.$200>> => {
        const formData = new FormData();
        formData.append('file', file);

        const query: ApiPaths.ApiV1DocumentV2.Post.QueryParameters = {
            documentType,
        };

        return this.doFetch<ApiPaths.ApiV1DocumentV2.Post.Responses.$200>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: 'POST',
            path: `/api/v1/document/v2`,
            query,
            expectedResponseContentType: 'unknown',
        });
    };

    deleteUser = async (
        userId: ApiPaths.ApiV1User$Id.Delete.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1User$Id.Delete.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1User$Id.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/api/v1/user/${userId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    cancelUserInvite = async (
        userId: ApiPaths.ApiV1User$UserIdCancelInvite.Delete.Parameters.UserId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1User$UserIdCancelInvite.Delete.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1User$UserIdCancelInvite.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/api/v1/user/${userId}/cancel-invite`,
            expectedResponseContentType: 'unknown',
        });
    };

    restoreUser = async (
        userId: ApiPaths.ApiV1User$IdRestore.Put.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1User$IdRestore.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1User$IdRestore.Put.Responses.$200>({
            method: 'PUT',
            path: `/api/v1/user/${userId}/restore`,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchCompanyContacts = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1CompanyContacts.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1CompanyContacts.Get.Responses.$200>({
            method: 'GET',
            path: '/api/v1/company/contacts',
            expectedResponseContentType: 'json',
        });
    };

    findCompanyUsersByName = async (
        query: string,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1CompanyContacts.Get.Responses.$200>> => {
        // TODO wait backend, there is no such endpoint
        return this.doFetch<ApiPaths.ApiV1CompanyContacts.Get.Responses.$200>({
            method: 'GET',
            path: '/api/v1/company/contacts',
            expectedResponseContentType: 'json',
        });
    };

    deleteContact = async (
        contactId: ApiPaths.ApiV1Contact$Id.Delete.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Contact$Id.Delete.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Contact$Id.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/api/v1/contact/${contactId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    updateContact = async (
        contactId: ApiPaths.ApiV1Contact$ContactId.Put.Parameters.ContactId,
        contactChanges: ApiPaths.ApiV1Contact$ContactId.Put.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Contact$ContactId.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Contact$ContactId.Put.Responses.$200>({
            method: 'PUT',
            path: `/api/v1/contact/${contactId}`,
            data: contactChanges,
            expectedResponseContentType: 'unknown',
        });
    };

    assignUserRole = async (
        userId: ApiPaths.ApiV1User$IdAssignRole$Role.Put.Parameters.Id,
        role: ApiPaths.ApiV1User$IdAssignRole$Role.Put.Parameters.Role,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1User$IdAssignRole$Role.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1User$IdAssignRole$Role.Put.Responses.$200>({
            method: 'PUT',
            path: `/api/v1/user/${userId}/assignRole/${role}`,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchContactDetails = async (
        contactId: ApiPaths.ApiV1Contact$Id.Get.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1Contact$Id.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1Contact$Id.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/contact/${contactId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchContactDetailsByUserId = async (
        userId: ApiPaths.ApiV1User$IdContact.Put.Parameters.Id,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1User$IdContact.Put.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1User$IdContact.Put.Responses.$200>({
            method: 'PUT',
            path: `/api/v1/user/${userId}/contact`,
            expectedResponseContentType: 'json',
        });
    };

    fetchSettings = async (): Promise<TranziitApiResultT<ApiPaths.ApiV1Settings.Get.Responses.$200>> => {
        const query: ApiPaths.ApiV1Settings.Get.QueryParameters = {
            groups: ['NEW_ORDER', 'ASSIGNMENT', 'SPOT'],
        };

        return this.doFetch<ApiPaths.ApiV1Settings.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/settings`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    uploadPaper = async (
        tourId: ApiPaths.ApiV1CarrierTour$TourIdDocuments.Post.PathParameters['tourId'],
        dictDocumentId: DictDocumentIdT,
        file: File,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1CarrierTour$TourIdDocuments.Post.Responses.$200>> => {
        const formData = new FormData();
        formData.append('file', file);

        const query: ApiPaths.ApiV1CarrierTour$TourIdDocuments.Post.QueryParameters = {
            dictCarrierDocumentId: toNumber(dictDocumentId),
        };

        return this.doFetch<ApiPaths.ApiV1CarrierTour$TourIdDocuments.Post.Responses.$200>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: 'POST',
            path: `/api/v1/carrier/tour/${tourId}/documents`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    deletePaper = async (
        tourId: ApiPaths.ApiV1CarrierTour$TourIdDocuments$DocumentId.Delete.PathParameters['tourId'],
        documentId: ApiPaths.ApiV1CarrierTour$TourIdDocuments$DocumentId.Delete.PathParameters['documentId'],
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1CarrierTour$TourIdDocuments$DocumentId.Delete.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1CarrierTour$TourIdDocuments$DocumentId.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/api/v1/carrier/tour/${tourId}/documents/${documentId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchRouteGeometryGoogle = async (
        id: Api2Paths.RoutingApiV1Geometry$IdGoogle.Get.PathParameters['id'],
    ): Promise<TranziitApiResultT<Api2Paths.RoutingApiV1Geometry$IdGoogle.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.RoutingApiV1Geometry$IdGoogle.Get.Responses.$200>({
            method: 'GET',
            path: `/routing/api/v1/geometry/${id}/google`,
            expectedResponseContentType: 'json',
        });
    };

    fetchPapers = async (
        tourId: ApiPaths.ApiV1CarrierTour$TourIdDocuments.Get.PathParameters['tourId'],
    ): Promise<TranziitApiResultT<ApiPaths.ApiV1CarrierTour$TourIdDocuments.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV1CarrierTour$TourIdDocuments.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v1/carrier/tour/${tourId}/documents`,
            expectedResponseContentType: 'json',
        });
    };

    fetchImage = async (src: string): Promise<TranziitApiResultT<string[]>> => {
        return this.doFetch<string[]>({
            method: 'GET',
            path: src,
            expectedResponseContentType: 'blob',
        });
    };

    fetchStateMachineEntityStatus = async (
        subjectType: Api2Paths.ApiV2Statemachine$SubjectIdStatus.Parameters.SubjectType,
        subjectId: Api2Paths.ApiV2Statemachine$SubjectIdStatus.Parameters.SubjectId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Statemachine$SubjectIdStatus.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Statemachine$SubjectIdStatus.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v2/statemachine/${subjectId}/status`,
            expectedResponseContentType: 'json',
            query: {
                subjectType,
            },
        });
    };

    updateStateMachineEntityStatus = async (
        subjectType: Api2Paths.ApiV2Statemachine$SubjectIdStatus.Parameters.SubjectType,
        subjectId: Api2Paths.ApiV2Statemachine$SubjectIdStatus.Parameters.SubjectId,
        data: Api2.Definitions.UpdateSubjectStatusRequest,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Statemachine$SubjectIdStatus.Put.Responses.$204>> => {
        return this.doFetch<Api2Paths.ApiV2Statemachine$SubjectIdStatus.Put.Responses.$204>({
            method: 'PUT',
            path: `/api/v2/statemachine/${subjectId}/status`,
            expectedResponseContentType: 'unknown',
            query: {
                subjectType,
            },
            data,
        });
    };

    createCompany = async (
        data: Api2Paths.ApiV1RegistrationSignUp.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1RegistrationSignUp.Post.Responses.$201>> => {
        return this.doFetch<Api2Paths.ApiV1RegistrationSignUp.Post.Responses.$201>({
            method: 'POST',
            path: `/api/v1/registration/sign-up`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    completeUserRegistration = async (
        data: Api2Paths.ApiV2RegistrationCompleteUserRegistration.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2RegistrationCompleteUserRegistration.Post.Responses.$201>> => {
        return this.doFetch<Api2Paths.ApiV2RegistrationCompleteUserRegistration.Post.Responses.$201>({
            method: 'POST',
            path: `/api/v2/registration/complete-user-registration`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    createMultipointOrderReservation = async (
        data: Api2Paths.ApiV2OrderReserves.Post.RequestBody,
        shipperId?: Api2Paths.ApiV2OrderReserves.Post.Parameters.ShipperId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2OrderReserves.Post.Responses.$200>> => {
        const query: Api2Paths.ApiV2OrderReserves.Post.QueryParameters = {
            shipperId,
        };
        return this.doFetch<Api2Paths.ApiV2OrderReserves.Post.Responses.$200>({
            method: 'POST',
            path: `/api/v2/order-reserves`,
            expectedResponseContentType: 'json',
            data,
            query,
        });
    };

    previewReserve = async (
        reserveId: Api2Paths.ApiV2OrderReserves$ReserveIdPreview.Put.Parameters.ReserveId,
        data: Api2Paths.ApiV2OrderReserves$ReserveIdPreview.Put.RequestBody,
        laneId?: Api2Paths.ApiV2OrderReserves$ReserveIdPreview.Put.Parameters.LaneId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2OrderReserves$ReserveIdPreview.Put.Responses.$200>> => {
        const query: Api2Paths.ApiV2OrderReserves$ReserveIdPreview.Put.QueryParameters = {
            laneId,
        };

        return this.doFetch<Api2Paths.ApiV2OrderReserves$ReserveIdPreview.Put.Responses.$200>({
            method: 'PUT',
            path: `/api/v2/order-reserves/${reserveId}/preview`,
            expectedResponseContentType: 'json',
            data,
            query,
        });
    };

    createMultipointOrderQuotation = async (
        reserveId: Api2Paths.ApiV2OrderReserves$ReserveIdRequestQuotations.Post.Parameters.ReserveId,
        data: Api2Paths.ApiV2OrderReserves$ReserveIdRequestQuotations.Post.RequestBody,
        laneId?: Api2Paths.ApiV2OrderReserves$ReserveIdRequestQuotations.Post.Parameters.LaneId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2OrderReserves$ReserveIdRequestQuotations.Post.Responses.$200>> => {
        const query: Api2Paths.ApiV2OrderReserves$ReserveIdRequestQuotations.Post.QueryParameters = {
            laneId,
        };

        return this.doFetch<Api2Paths.ApiV2OrderReserves$ReserveIdRequestQuotations.Post.Responses.$200>({
            method: 'POST',
            path: `/api/v2/order-reserves/${reserveId}/request-quotations`,
            expectedResponseContentType: 'json',
            data,
            query,
        });
    };

    getRFQDetails = async (
        rfqId: Api2Paths.ApiV2Rfqs$RfqId.Get.Parameters.RfqId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Rfqs$RfqId.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Rfqs$RfqId.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v2/rfqs/${rfqId}`,
            expectedResponseContentType: 'json',
        });
    };

    getPriceOffers = async (
        rfqId: Api2Paths.ApiV2Rfqs$RfqIdOffers.Get.Parameters.RfqId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2Rfqs$RfqIdOffers.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2Rfqs$RfqIdOffers.Get.Responses.$200>({
            method: 'GET',
            path: `/api/v2/rfqs/${rfqId}/offers`,
            expectedResponseContentType: 'json',
        });
    };

    completeRFQ = async (
        rfqId: Api2Paths.ApiV2Rfqs$RfqIdStatusCompleted.Post.Parameters.RfqId,
        data: Api2Paths.ApiV2Rfqs$RfqIdStatusCompleted.Post.RequestBody,
    ): Promise<
        TranziitApiResultT<
            Api2Paths.ApiV2Rfqs$RfqIdStatusCompleted.Post.Responses.$200,
            Api2Paths.ApiV2Rfqs$RfqIdStatusCompleted.Post.Responses.$400
        >
    > => {
        return this.doFetch<Api2Paths.ApiV2Rfqs$RfqIdStatusCompleted.Post.Responses.$200>({
            method: 'POST',
            path: `/api/v2/rfqs/${rfqId}/status/completed`,
            expectedResponseContentType: 'json',
            data,
        });
    };
}

const commonTranziitApi = CommonTranziitApi.getInstance();

export default commonTranziitApi;
