import { CompanyDriverDetailsT, CompanyDriverT, FetchCompanyDriversPageQueryT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

import { ApiInviteDriverRequestT, ApiUpdateDriverRequestT } from 'common/utils/api/models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_DRIVERS_PAGE_REQUEST = 'common/drivers/FETCH_DRIVERS_PAGE_REQUEST';
export const FETCH_DRIVERS_PAGE_REQUEST_BEGIN = 'common/drivers/FETCH_DRIVERS_PAGE_REQUEST_BEGIN';
export const FETCH_DRIVERS_PAGE_REQUEST_SUCCESS = 'common/drivers/FETCH_DRIVERS_PAGE_REQUEST_SUCCESS';
export const FETCH_DRIVERS_PAGE_REQUEST_ERROR = 'common/drivers/FETCH_DRIVERS_PAGE_REQUEST_ERROR';
export const RESET_DRIVERS = 'common/drivers/RESET_DRIVERS';

export const FETCH_DRIVER_DETAILS_REQUEST = 'common/drivers/FETCH_DRIVER_DETAILS_REQUEST';
export const FETCH_DRIVER_DETAILS_REQUEST_BEGIN = 'common/drivers/FETCH_DRIVER_DETAILS_REQUEST_BEGIN';
export const FETCH_DRIVER_DETAILS_REQUEST_SUCCESS = 'common/drivers/FETCH_DRIVER_DETAILS_REQUEST_SUCCESS';
export const FETCH_DRIVER_DETAILS_REQUEST_ERROR = 'common/drivers/FETCH_DRIVER_DETAILS_REQUEST_ERROR';

export const INVITE_DRIVER_REQUEST = 'common/drivers/INVITE_DRIVER_REQUEST';
export const INVITE_DRIVER_REQUEST_BEGIN = 'common/drivers/INVITE_DRIVER_REQUEST_BEGIN';
export const INVITE_DRIVER_REQUEST_SUCCESS = 'common/drivers/INVITE_DRIVER_REQUEST_SUCCESS';
export const INVITE_DRIVER_REQUEST_ERROR = 'common/drivers/INVITE_DRIVER_REQUEST_ERROR';

export const DELETE_DRIVER_REQUEST = 'common/drivers/DELETE_DRIVER_REQUEST';
export const DELETE_DRIVER_REQUEST_BEGIN = 'common/drivers/DELETE_DRIVER_REQUEST_BEGIN';
export const DELETE_DRIVER_REQUEST_SUCCESS = 'common/drivers/DELETE_DRIVER_REQUEST_SUCCESS';
export const DELETE_DRIVER_REQUEST_ERROR = 'common/drivers/DELETE_DRIVER_REQUEST_ERROR';

export const ACTIVATE_DRIVER = 'common/drivers/ACTIVATE_DRIVER';
export const ARCHIVE_DRIVER = 'common/drivers/ARCHIVE_DRIVER';
export const CANCEL_DRIVER_INVITE = 'common/drivers/CANCEL_DRIVER_INVITE';

export const UPDATE_DRIVER_REQUEST = 'common/drivers/UPDATE_DRIVER_REQUEST';
export const UPDATE_DRIVER_REQUEST_BEGIN = 'common/drivers/UPDATE_DRIVER_REQUEST_BEGIN';
export const UPDATE_DRIVER_REQUEST_SUCCESS = 'common/drivers/UPDATE_DRIVER_REQUEST_SUCCESS';
export const UPDATE_DRIVER_REQUEST_ERROR = 'common/drivers/UPDATE_DRIVER_REQUEST_ERROR';

export type FetchDriversPageActionT = {
    type: typeof FETCH_DRIVERS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    companyId: CompanyIdT;
    query: FetchCompanyDriversPageQueryT;
    options?: FetchOptionsT;
};

export type FetchDriversPageBeginActionT = {
    type: typeof FETCH_DRIVERS_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchCompanyDriversPageQueryT;
    companyId: CompanyIdT;
};

export type FetchDriversPageSuccessActionT = {
    type: typeof FETCH_DRIVERS_PAGE_REQUEST_SUCCESS;
    pageNumber: PageNumberT;
    query: FetchCompanyDriversPageQueryT;
    pageResponse: PageResponseT<CompanyDriverT> | null;
    companyId: CompanyIdT;
};

export type FetchDriversPageErrorActionT = {
    type: typeof FETCH_DRIVERS_PAGE_REQUEST_ERROR;
    pageNumber: PageNumberT;
    error: Error;
    query: FetchCompanyDriversPageQueryT;
    companyId: CompanyIdT;
};

export type ResetDriversActionT = {
    type: typeof RESET_DRIVERS;
    companyId: CompanyIdT;
    mutationListOptions: MutationListOptionsT;
};

export type InviteDriverActionT = {
    type: typeof INVITE_DRIVER_REQUEST;
    newDriver: ApiInviteDriverRequestT;
    companyId: CompanyIdT;
};

export type InviteDriverBeginActionT = {
    type: typeof INVITE_DRIVER_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type InviteDriverSuccessActionT = {
    type: typeof INVITE_DRIVER_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type InviteDriverErrorActionT = {
    type: typeof INVITE_DRIVER_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type DeleteDriverActionT = {
    type: typeof DELETE_DRIVER_REQUEST;
    driverId: DriverIdT;
    companyId: CompanyIdT;
};

export type DeleteDriverBeginActionT = {
    type: typeof DELETE_DRIVER_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type DeleteDriverSuccessActionT = {
    type: typeof DELETE_DRIVER_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type DeleteDriverErrorActionT = {
    type: typeof DELETE_DRIVER_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type UpdateDriverActionT = {
    type: typeof UPDATE_DRIVER_REQUEST;
    driverId: DriverIdT;
    driverChanges: ApiUpdateDriverRequestT;
    companyId: CompanyIdT;
};

export type ActivateDriverActionT = {
    type: typeof ACTIVATE_DRIVER;
    driverId: DriverIdT;
    companyId: CompanyIdT;
};

export type ArchiveDriverActionT = {
    type: typeof ARCHIVE_DRIVER;
    driverId: DriverIdT;
    companyId: CompanyIdT;
};

export type CancelDriverInviteActionT = {
    type: typeof CANCEL_DRIVER_INVITE;
    companyId: CompanyIdT;
    driverUserId: UserIdT;
};

export type UpdateDriverBeginActionT = {
    type: typeof UPDATE_DRIVER_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type UpdateDriverSuccessActionT = {
    type: typeof UPDATE_DRIVER_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type UpdateDriverErrorActionT = {
    type: typeof UPDATE_DRIVER_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type FetchDriverDetailsActionT = {
    type: typeof FETCH_DRIVER_DETAILS_REQUEST;
    companyId: CompanyIdT;
    driverId: DriverIdT;
    fetchOptions: FetchOptionsT;
};

export type FetchDriverDetailsBeginActionT = {
    type: typeof FETCH_DRIVER_DETAILS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type FetchDriverDetailsSuccessActionT = {
    type: typeof FETCH_DRIVER_DETAILS_REQUEST_SUCCESS;
    driverId: DriverIdT;
    companyId: CompanyIdT;
    details: CompanyDriverDetailsT | null;
};

export type FetchDriverDetailsErrorActionT = {
    type: typeof FETCH_DRIVER_DETAILS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type DriversActionT =
    | FetchDriversPageActionT
    | FetchDriversPageBeginActionT
    | FetchDriversPageSuccessActionT
    | FetchDriversPageErrorActionT
    | InviteDriverActionT
    | InviteDriverBeginActionT
    | InviteDriverSuccessActionT
    | InviteDriverErrorActionT
    | DeleteDriverActionT
    | DeleteDriverBeginActionT
    | DeleteDriverSuccessActionT
    | DeleteDriverErrorActionT
    | ActivateDriverActionT
    | ArchiveDriverActionT
    | UpdateDriverActionT
    | UpdateDriverBeginActionT
    | UpdateDriverSuccessActionT
    | UpdateDriverErrorActionT
    | FetchDriverDetailsActionT
    | FetchDriverDetailsBeginActionT
    | FetchDriverDetailsSuccessActionT
    | FetchDriverDetailsErrorActionT
    | ResetDriversActionT;

export type DriverDetailsByIdT = Record<DriverIdT, CompanyDriverDetailsT | null>;

export type CompanyDriversStateT = PaginationStateT<CompanyDriverT, FetchCompanyDriversPageQueryT> & {
    inviteRequest: RequestStatusT;
    deleteRequest: RequestStatusT;
    updateRequest: RequestStatusT;
    fetchDetailsRequest: RequestStatusT;
    detailsById: DriverDetailsByIdT;
};

export type DriversStateT = Record<CompanyIdT, CompanyDriversStateT | null>;
