import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './DriversTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';

import { CompanyDriverT } from 'common/store/drivers/models';

import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';

import AvatarCell from 'common/components/Table/cell-renderers/AvatarCell/AvatarCell';
import StatusCell from './cell-renderers/StatusCell/StatusCell';
import { isNonNil } from 'common/utils';
import FullNameCell from 'common/components/Table/cell-renderers/FullNameCell/FullNameCell';
import PhoneCell from 'common/components/Table/cell-renderers/PhoneCell/PhoneCell';

const cx = classNames.bind(styles);

type PropsT = {
    drivers: Array<CompanyDriverT>;
    selectedDriverId: DriverIdT | null;
    className?: string;
    onSelectRow: (event: React.MouseEvent, driver: CompanyDriverT) => void;
    isLoading: boolean;
    isShowStatus: boolean;
};

const DriversTable: React.FC<PropsT> = (props) => {
    const { drivers, selectedDriverId, className, onSelectRow, isLoading, isShowStatus } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<CompanyDriverT, void>> = [
        {
            renderHeader: () => null,
            render: (driver: CompanyDriverT) => <AvatarCell fullName={driver.name || ''} />,
            className: cx('table__column--avatar'),
        },
        {
            renderHeader: () => <span>{t('common:drivers-page.table.columns.name')}</span>,
            headerClassName: cx('table__header', 'table__header--name'),
            render: (driver: CompanyDriverT) => <FullNameCell fullName={driver.name || ''} />,
            className: cx('table__column--any'),
        },
        {
            renderHeader: () => <span>{t('common:drivers-page.table.columns.phone')}</span>,
            headerClassName: cx('table__header'),
            render: (driver: CompanyDriverT) => <PhoneCell phone={driver.mobilePhone || ''} />,
            className: cx('table__column--any'),
        },
        isShowStatus
            ? {
                  renderHeader: () => null,
                  render: (driver: CompanyDriverT) => <StatusCell driver={driver || ''} />,
                  className: cx('table__column--status'),
              }
            : null,
    ].filter(isNonNil);

    const getRowMods = (meta: TableRowMetaT, row: CompanyDriverT) => ({
        isSelected: row?.id === selectedDriverId,
    });

    return (
        <div className={cx('table')}>
            <MemoizedTable<CompanyDriverT> tableName="drivers" isLoading={isLoading} rows={drivers}>
                {(rows, isUsedPrevRows) => (
                    <Table<CompanyDriverT, void>
                        className={className}
                        columns={columns}
                        rows={rows}
                        onSelectRow={onSelectRow}
                        getRowMods={getRowMods}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
};

export default DriversTable;
