import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ColoredStatusLabel.scss';
import cs from 'classnames';
import CircleIcon from 'common/icons/CircleIcon';
import { StyleGuideColorsEnum } from 'common/constants';

// confluence entity
export const colorCodingColoredStatusTheme = {
    normal: StyleGuideColorsEnum.slate,
    attention: StyleGuideColorsEnum.orange,
    warning: StyleGuideColorsEnum.tomatoRed,
    notImportant: StyleGuideColorsEnum.gray,
    success: StyleGuideColorsEnum.brandAccent,
    positive: StyleGuideColorsEnum.brandAccent,
    focus: StyleGuideColorsEnum.charcoal,
} as const;

export type PropsT = {
    className?: string;
    label: React.ReactNode;
    color: StyleGuideColorsEnum;
    isRightIcon?: boolean;
    isInline?: boolean;
    labelClassName?: string;
    isWithoutVerticalPaddings?: boolean;
};

const cx = classNames.bind(styles);

const ColoredStatusLabel: React.FC<PropsT> = React.memo((props) => {
    const { label, isRightIcon, isInline, color, className, labelClassName, isWithoutVerticalPaddings } = props;

    return (
        <div
            className={cs(
                className,
                cx('wrap', {
                    'wrap--isRightIcon': isRightIcon,
                    'wrap--isInline': isInline,
                }),
            )}
        >
            <div
                className={cx('icon', {
                    'icon--withVerticalPaddings': !isWithoutVerticalPaddings,
                })}
            >
                <CircleIcon borderColor={color} />
            </div>
            <div className={cs(cx('label', labelClassName))}>{label}</div>
        </div>
    );
});

export default ColoredStatusLabel;
