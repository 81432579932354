import { FieldsEnum, FormValuesT } from './constants';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'common/layouts/DriversPageLayout/query-models';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const queryFilters: QueryFiltersT = {};

    const parsedName = simpleStringFormatter(values[FieldsEnum.name]);
    if (parsedName) {
        queryFilters[QueryFiltersKeysEnum.searchText] = undefined;
        queryFilters[QueryFiltersKeysEnum.name] = parsedName;
    }

    return queryFilters;
};

export default getQueryFilters;
