import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './CreatePartnerInfo.scss';
import { useTranslation } from 'react-i18next';
import { useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { logWarning } from 'common/utils/logger';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import Link, { LinkSizeEnum, LinkThemeEnum } from 'common/components/Link/Link';
import moment from 'moment/moment';
import { clientConfig } from 'common/utils/client-config';

type PropsT = {
    partnerId: PartnerIdT | null;
    partnerType: PartnerTypeEnum | null;
    createdByBroker: boolean;
    createdDate: string | null;
    userId: UserIdT | null;
    userName: string | null;
};

const formatHMRDate = (date: string | null | undefined): string => {
    if (!date) {
        return '-';
    }

    return moment(date).format('Do MMM YYYY');
};

const cx = classNames.bind(styles);

const CreatePartnerInfo: React.FC<PropsT> = React.memo((props) => {
    const { createdDate, createdByBroker, userId, userName, partnerId, partnerType } = props;

    const openLeftSidebar = useOpenLeftSidebar();

    const openUserDetails = () => {
        if (!userId || !partnerId || !partnerType) {
            logWarning(`Failed to open user details, empty userId`);
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const { t } = useTranslation();

    if (!createdDate) {
        return null;
    }

    const renderUser = () => {
        if (createdByBroker && !clientConfig.isBrokerProject) {
            return <div className={cx('broker')}>{t('common:broker-name')}</div>;
        }

        if (!userId) {
            return null;
        }

        return (
            <ClickInterceptorLabel>
                <Link onClick={openUserDetails} theme={LinkThemeEnum.boldBrandDark} size={LinkSizeEnum.preExtraSmall}>
                    {userName}
                </Link>
            </ClickInterceptorLabel>
        );
    };

    return (
        <div className={cx('wrap')}>
            <div className={cx('text')}>
                {t('common:profile-page.basic-information.create-info.date', {
                    date: formatHMRDate(createdDate),
                })}
            </div>
            {renderUser()}
        </div>
    );
});

export default CreatePartnerInfo;
