import * as React from 'react';
import { ApiUserStatusT, UserStatusEnum } from 'common/utils/api/models';
import { PropsT as ColoredStatusLabelPropsT } from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import UserStatus from 'common/components/status/UserStatus/UserStatus';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiUserStatusT | null;
    isSymmetrical?: boolean;
};

const DriverStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (status === UserStatusEnum.archived) {
        return (
            <PillLabel isNoWrap {...restProps} theme={PillLabelThemeEnum.slate}>
                <UserStatus status={status} />
            </PillLabel>
        );
    }

    if (status === UserStatusEnum.pending) {
        return (
            <PillLabel isNoWrap {...restProps} theme={PillLabelThemeEnum.orange}>
                <UserStatus status={status} />
            </PillLabel>
        );
    }

    return null;
});

export default DriverStatusPill;
