import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './Checkbox.scss';
import cs from 'classnames';

const cx = classNames.bind(styles);

export enum CheckboxThemeEnum {
    orange = 'orange',
    green = 'green',
}

export type PropsT = {
    className?: string;
    checked: boolean;
    indeterminated?: boolean;
    onChange?: (checked: boolean, event: React.MouseEvent<HTMLDivElement>) => void;
    isFullWidth?: boolean;
    isDisabled?: boolean;
    theme?: CheckboxThemeEnum | null;
    label?: React.ReactNode;
    rightIcon?: React.ReactNode;
    testSelector?: string;
};

const Checkbox: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        theme,
        checked,
        indeterminated,
        onChange,
        isDisabled,
        label,
        rightIcon,
        isFullWidth,
        testSelector,
    } = props;

    const [isHover, setHover] = React.useState<boolean>(false);

    const handleChange = (event: React.MouseEvent<HTMLDivElement>) => {
        if (isDisabled) {
            return;
        }

        if (onChange) {
            onChange(!checked, event);
        }
    };

    const handleMouseMove = () => {
        if (isHover) {
            return;
        }

        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    const fullTestSelector = `${testSelector}_checkbox`;

    return (
        <div
            className={cs(
                cx('checkbox', {
                    'checkbox--isFullWidth': isFullWidth,
                }),
                className,
            )}
        >
            <div
                className={cx('box', {
                    'box--isHover': isHover,
                    'box--isClickable': !isDisabled,
                    'box--isChecked': checked,
                    'box--isIndeterminated': indeterminated,
                    'box--isDisabled': isDisabled,
                    [`box--theme-${theme}`]: true,
                })}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onClick={handleChange}
                data-test-selector={`${fullTestSelector}_box`}
            />
            {!!label && (
                <div
                    className={cx('label', {
                        'label--isFullWidth': isFullWidth,
                        'label--isClickable': !isDisabled,
                        'label--isDisabled': isDisabled,
                        'label--hasRightIcon': !!rightIcon,
                    })}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleChange}
                    data-test-selector={`${fullTestSelector}_label`}
                >
                    {label}
                </div>
            )}
            {rightIcon}
        </div>
    );
});

export default Checkbox;
