import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchCompanyDriversPageQueryT } from 'common/store/drivers/models';
import { TabEnum } from './constants';
import { DriversSortEnum, UserStatusEnum } from 'common/utils/api/models';
import difference from 'lodash/difference';
import { PageSortT } from 'common/utils/query';

const allDriverStatuses = Object.values(UserStatusEnum);

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    activeTab: TabEnum | null | undefined,
    sort: PageSortT<DriversSortEnum> | null,
): FetchCompanyDriversPageQueryT => {
    let statuses: UserStatusEnum[] = [];

    if (activeTab === TabEnum.active) {
        statuses = difference(allDriverStatuses, [UserStatusEnum.archived]);
    } else {
        statuses = [UserStatusEnum.archived];
    }

    return {
        name: queryFilters[QueryFiltersKeysEnum.searchText] || queryFilters[QueryFiltersKeysEnum.name] || undefined,
        statuses,
        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
