import React from 'react';
import classNames from 'classnames/bind';

import styles from './DropdownControl.scss';
import { DropdownOverlayPositionEnum } from '../constants';
import DropdownBaseLayout from 'design-system/components/dropdowns/base/DropdownBaseLayout/DropdownBaseLayout';
import { CheckboxThemeEnum } from 'design-system/components/Checkbox/Checkbox';

const cx = classNames.bind(styles);

export type DropdownControlOptionT = {
    label: React.ReactNode;
    onSelect: () => void;
    isDisabled?: boolean;
    testSelector?: string;
    checkboxTheme?: CheckboxThemeEnum;
};

type OpenTriggerT = (event: React.MouseEvent<HTMLElement>) => void;

export enum SpecialOptionEnum {
    separator = 'special-option-separator',
}

const allSpecialOptions = Object.values(SpecialOptionEnum);

export const checkIsSpecialOption = (option: TODO): option is SpecialOptionEnum => {
    return allSpecialOptions.includes(option);
};

export type DropdownControlPropsT = {
    isInline?: boolean;
    isDisabled?: boolean;
    options: Array<DropdownControlOptionT | SpecialOptionEnum | null | undefined>;
    className?: string;
    overlayClassName?: string;
    renderTrigger: (isActive: boolean, onClick: OpenTriggerT) => React.ReactElement;
    overlayPosition: DropdownOverlayPositionEnum;
    testSelector?: string;
    isShowTriggerForEmptyOptions?: boolean;
};

const DropdownControl: React.FC<DropdownControlPropsT> = React.memo((props) => {
    const {
        options,
        isInline,
        isDisabled,
        renderTrigger,
        className,
        overlayPosition,
        overlayClassName,
        isShowTriggerForEmptyOptions,
        testSelector,
    } = props;

    const fullTestSelector = `${testSelector}_dropdown`;

    const [isOpen, toggleOpen] = React.useState(false);

    const handleOpen: OpenTriggerT = (event) => {
        if (isDisabled) {
            return;
        }

        if (event) {
            event.stopPropagation();
        }

        toggleOpen(!isOpen);
    };

    const handleClose = (): void => {
        toggleOpen(false);
    };

    const handleOuterEvent = (): void => {
        handleClose();
    };

    const viewOptions = options.reduce<React.ReactNode[]>((viewOptions, option, index) => {
        if (!option) {
            return viewOptions;
        }

        if (checkIsSpecialOption(option)) {
            viewOptions.push(<div key={index} className={cx('overlay__separator')} />);
        } else {
            const { label, onSelect, isDisabled: isDisabledOption } = option;

            viewOptions.push(
                <div
                    key={index}
                    className={cx('overlay__option', {
                        'overlay__option--isDisabled': isDisabledOption,
                    })}
                    onClick={(event): void => {
                        if (isDisabled || isDisabledOption) {
                            return;
                        }

                        if (onSelect) {
                            onSelect();
                        }

                        handleClose();
                        event.stopPropagation();
                    }}
                    date-test-selector={`${fullTestSelector}_option_${option.testSelector || index}`}
                >
                    {label}
                </div>,
            );
        }

        return viewOptions;
    }, []);

    if (!viewOptions.length && !isShowTriggerForEmptyOptions) {
        return null;
    }

    return (
        <DropdownBaseLayout
            withoutScroll
            isOpen={isOpen}
            isInline={isInline}
            overlayClassName={overlayClassName}
            overlayPosition={overlayPosition}
            className={className}
            triggerNode={renderTrigger(isOpen, handleOpen)}
            overlayNode={<>{viewOptions}</>}
            onClose={handleOuterEvent}
        />
    );
});

export default DropdownControl;
