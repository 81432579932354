export enum QueryKeysEnum {
    driversPage = 'drivers-page',
    driversSort = 'drivers-sort',
    driversFilters = 'drivers-filters',
    driversTab = 'drivers-tab',
}

export enum QueryFiltersKeysEnum {
    searchText = 'searchText',
    name = 'name',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
    [QueryFiltersKeysEnum.name]: QueryParam.StringT;
}>;
